<template>
    <div>Advisor image not found</div>
</template>

<script>
export default {
    name: "NotFound",
    created() {
        // this.$router.go("/error")
    }
}
</script>